import React from "react";
import { SubscriptionStripeApi } from "../../types";

interface BadgeStatusStripeProps {
  subscriptionStripeApi: SubscriptionStripeApi;
}
const BadgeStatusStripe = ({
  subscriptionStripeApi,
}: BadgeStatusStripeProps) => {
  switch (subscriptionStripeApi.status) {
    case "active":
      if (subscriptionStripeApi.cancel_at_period_end) {
        return (
          <span className="inline-block px-2 py-px bg-amber-700 text-neutral-700 rounded-lg">
            Active but billing canceled
          </span>
        );
      }
      return (
        <span className="inline-block px-2 py-px bg-green-700 text-white rounded-lg">
          Active
        </span>
      );
    case "trialing":
      if (subscriptionStripeApi.cancel_at_period_end) {
        return (
          <span className="inline-block px-2 py-px bg-amber-700 text-neutral-700 rounded-lg">
            Trialing but billing canceled
          </span>
        );
      }
      return (
        <span className="inline-block px-2 py-px bg-green-700 text-white rounded-lg">
          Trialing
        </span>
      );
    case "canceled":
      return (
        <span className="inline-block px-2 py-px bg-red-700 text-white rounded-lg">
          Expired
        </span>
      );
    case "past_due":
      return (
        <span className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg">
          Past due
        </span>
      );
    default:
      return (
        <span className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg">
          {subscriptionStripeApi.status}
        </span>
      );
  }
};

export default BadgeStatusStripe;
