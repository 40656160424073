import { Link } from "gatsby";
import React from "react";
import { CmSubscriptionStripe } from "../../types";
import { formatDateOnly } from "../../utils";
import BadgeStatusStripe from "./BadgeStatusStripe";
import NextPaymentStripe from "./NextPaymentStripe";
import {
  useStripeInvoiceUpcomingQuery,
  useStripeSubscriptionQuery,
} from "./subscriptionApiSlice";

interface SubscriptionStripeProps {
  subscription: CmSubscriptionStripe;
}
const SubscriptionStripe = ({ subscription }: SubscriptionStripeProps) => {
  const { data: subscriptionStripeApi } = useStripeSubscriptionQuery(
    subscription.id
  );
  const { data: invoiceUpcoming } = useStripeInvoiceUpcomingQuery(
    subscription.id
  );

  if (!subscriptionStripeApi) {
    return null;
  }

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 items-center">
        <div className="col-span-5 sm:col-span-3 text-neutral-700 self-start">
          Subscription status:
        </div>
        <div className="col-span-7 sm:col-span-9">
          <BadgeStatusStripe subscriptionStripeApi={subscriptionStripeApi} />
          {subscriptionStripeApi.status === "active" &&
            subscriptionStripeApi.cancel_at_period_end && (
              <div className="text-neutral-700 mt-px">
                Subscription ends on{" "}
                {formatDateOnly(subscriptionStripeApi.current_period_end)}
              </div>
            )}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 bg-white rounded-lg py-3 my-6 relative">
        <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700">
          Start date:
        </div>
        <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700">
          {formatDateOnly(subscription.created)}
        </div>
        {subscription?.ended_on && (
          <>
            <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700">
              Ended on:
            </div>
            <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700">
              {formatDateOnly(subscription.ended_on)}
            </div>
          </>
        )}
        <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700 self-start">
          Plan name:
        </div>
        <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700 flex flex-wrap gap-x-6 gap-y-2">
          {subscription.plan_name}
        </div>

        {subscriptionStripeApi.status !== "trialing" && (
          <div className="col-span-7 col-start-6 sm:col-span-9 sm:col-start-4">
            <Link
              className="text-blue-700 font-bold hover:text-blue-600"
              to={`/subscription/billing-history/${subscription.id}`}
            >
              view billing history
            </Link>
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-3 items-center">
        <div className="col-span-5 sm:col-span-3 text-neutral-700 self-start">
          Next payment:
        </div>
        <div className="col-span-7 sm:col-span-9">
          <NextPaymentStripe
            subscription={subscription}
            subscriptionStripeApi={subscriptionStripeApi}
            invoiceUpcoming={invoiceUpcoming}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStripe;
