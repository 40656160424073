import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import SubscriptionPaddle from "./SubscriptionPaddle";
import { useSubscriptionsByUserQuery } from "./subscriptionApiSlice";
import { SpinnerCircularFixed } from "spinners-react";
import {
  CmSubscriptionFree,
  CmSubscriptionPaddle,
  CmSubscriptionStripe,
} from "../../types";
import SubscriptionStripe from "./SubscriptionStripe";
import { useCurrentUserQuery } from "../user/userApiSlice";

const Subscriptions = () => {
  const [sectionTitle, setSectionTitle] = useState("Subscription");
  const [subscriptions, setSubscriptions] = useState<
    (CmSubscriptionPaddle | CmSubscriptionFree | CmSubscriptionStripe)[]
  >([]);
  const { data: currentUser } = useCurrentUserQuery();
  const {
    data: dataSubscriptions,
    isFetching,
    isSuccess,
  } = useSubscriptionsByUserQuery(
    currentUser?.is_authenticated ? currentUser.data.uid : skipToken
  );

  useEffect(() => {
    if (dataSubscriptions && currentUser && !isFetching) {
      let subscriptions = [...dataSubscriptions];
      subscriptions.sort((subscriptionA, subscriptionB) =>
        subscriptionA.created > subscriptionB.created ? -1 : 1
      );
      if (subscriptions.length > 1) {
        setSectionTitle("Subscriptions");
      }
      setSubscriptions(subscriptions);
    } else {
      setSubscriptions([]);
    }
  }, [isFetching, dataSubscriptions, currentUser]);

  return (
    <>
      <SectionTitle backgroundStyle="white" title={sectionTitle} />
      <div className="flex justify-center">
        <SpinnerCircularFixed
          className="inline-block mt-4"
          size="40"
          color="#D3D3D3"
          secondaryColor="rgb(75, 85, 99)"
          enabled={subscriptions.length === 0 && !isSuccess}
        />
      </div>
      <div className="divide-y grid gap-8 divide-neutral-200">
        {subscriptions.length > 0 &&
          subscriptions.map((subscription) => {
            return (
              <div key={subscription.id} className="pt-8">
                <Subscription subscription={subscription} />
              </div>
            );
          })}
        {subscriptions.length === 0 && isSuccess && (
          <div className="text-neutral-700 mt-2">No subscription found</div>
        )}
      </div>
    </>
  );
};

const Subscription = ({
  subscription,
}: {
  subscription:
    | CmSubscriptionPaddle
    | CmSubscriptionFree
    | CmSubscriptionStripe;
}) => {
  switch (subscription.bundle) {
    case "paddle":
      return <SubscriptionPaddle subscription={subscription} />;
    case "stripe":
      return <SubscriptionStripe subscription={subscription} />;
    default:
      return null;
  }
};

export default Subscriptions;
