import { navigate } from "gatsby";
import * as Sentry from "@sentry/gatsby";

export const initializePaddleScript = () => {
  if (process.env.GATSBY_ENVIRONMENT === "sandbox") {
    window.Paddle.Environment.set("sandbox");
  }
  window.Paddle.Setup({
    vendor: parseInt(process.env.GATSBY_PADDLE_VENDOR_ID),
    // https://developer.paddle.com/reference/ZG9jOjI1MzU0MDIx-checkout-events#paddlejs-event-callback
    eventCallback: (data) => {
      if (data.event === "Checkout.PaymentComplete") {
        if (data.eventData.flagged) {
          navigate("/manual-approval");
        }
      }
      if (data.event === "Checkout.Error") {
        const e = Error(data.eventData.error);
        e.name = data.event + " " + data.eventData.error_type;
        Sentry.captureException(e);
        if (typeof gtag === "function") {
          gtag("event", "checkout_error", {
            error_type: data.eventData.error_type,
            message: data.eventData.error,
          });
        }
      }
      if (data.event === "Checkout.Loaded") {
        console.log(data);
      }
      if (data.event === "Checkout.Success") {
        console.log(data);
      }
      if (
        data.event === "Checkout.PaymentMethodSelected" &&
        typeof gtag === "function"
      ) {
        gtag("event", "add_payment_info", {
          currency: data.eventData.checkout.prices.customer.currency,
          value: parseFloat(data.eventData.checkout.prices.customer.total),
          payment_type: data.eventData.payment_method,
          items: [
            {
              item_id: data.eventData.product.id,
              item_name: data.eventData.product.name,
              price: parseFloat(data.eventData.checkout.prices.customer.total),
            },
          ],
        });
      }
    },
  });
};
