import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import toast from "react-hot-toast";
import { handleErrorResponse } from "../../app/apiSlice";
import { CmSubscriptionPaddle, CmSubscriptionStripe } from "../../types";
import {
  usePaddleApiSubscriptionCancelBillingMutation,
  useStripeCancelBillingMutation,
} from "./subscriptionApiSlice";
import * as Sentry from "@sentry/gatsby";

interface CancelBillingProps {
  subscription: CmSubscriptionPaddle | CmSubscriptionStripe;
  open: boolean;
  setOpen: (arg: boolean) => void;
}
const CancelBilling = ({ subscription, open, setOpen }: CancelBillingProps) => {
  const [cancelBillingTriggerPaddle, { isLoading: isLoadingPaddle }] =
    usePaddleApiSubscriptionCancelBillingMutation();

  const [cancelBillingTriggerStripe, { isLoading: isLoadingStripe }] =
    useStripeCancelBillingMutation();

  const handleCancelBilling = () => {
    if (subscription.bundle === "paddle") {
      Sentry.captureMessage('canceling billing for subscription', {contexts: {'subscription': {subscription}}});
      cancelBillingTriggerPaddle(subscription.id)
        .unwrap()
        .then((success) => {
          toast.success("Billing has been canceled");
          setOpen(false);
        })
        .catch((error) => {
          console.error(error);
          handleErrorResponse(error).forEach((m) => {
            const e = Error(m);
            e.name = "handleCancelBilling: paddle";
            Sentry.captureException(e);
          });
          setOpen(false);
        });
    }
    if (subscription.bundle === "stripe") {
      cancelBillingTriggerStripe(subscription.id)
        .unwrap()
        .then((success) => {
          toast.success("Billing has been canceled");
          setOpen(false);
        })
        .catch((error) => {
          console.error(error);
          handleErrorResponse(error).forEach((m) => {
            const e = Error(m);
            e.name = "handleCancelBilling: stripe";
            Sentry.captureException(e);
          });
          setOpen(false);
        });
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-300 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-bold text-neutral-700"
                    >
                      Cancel automatic billing
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-neutral-600">
                        You will still have access until the end of your current
                        billing period. Are you sure you wish to cancel
                        automatic billing?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm 
                    px-4 py-2 bg-blue-700 text-base text-white hover:bg-blue-600 focus:outline-none focus:ring-2 
                    focus:ring-offset-2 focus:ring-blue-500 "
                    onClick={handleCancelBilling}
                    disabled={isLoadingPaddle || isLoadingStripe}
                  >
                    Yes, cancel billing
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-lg border border-neutral-300 shadow-sm 
                    px-4 py-2 bg-white text-base text-neutral-700 hover:bg-neutral-100 focus:outline-none focus:ring-2 
                    focus:ring-offset-2 focus:ring-blue-500 sm:mt-0  "
                    onClick={() => setOpen(false)}
                    disabled={isLoadingPaddle || isLoadingStripe}
                  >
                    No, don't cancel billing
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CancelBilling;
