import React, { Fragment, useState } from "react";
import toast from "react-hot-toast";
import { handleErrorResponse } from "../../app/apiSlice";
import {
  CmSubscriptionStripe,
  InvoiceUpcomingStripeApi,
  SubscriptionStripeApi,
} from "../../types";
import { formatDateOnly, getPriceLocalized } from "../../utils";
import CancelBilling from "./CancelBilling";
import { useStripeCancelMutation } from "./subscriptionApiSlice";
import * as Sentry from "@sentry/gatsby";

interface NextPaymentStripeProps {
  subscription: CmSubscriptionStripe;
  subscriptionStripeApi: SubscriptionStripeApi;
  invoiceUpcoming: InvoiceUpcomingStripeApi | undefined;
}
const NextPaymentStripe = ({
  subscription,
  subscriptionStripeApi,
  invoiceUpcoming,
}: NextPaymentStripeProps) => {
  const [open, setOpen] = useState(false);
  const [cancelTrigger, { isLoading }] = useStripeCancelMutation();

  const handleCancel = () => {
    cancelTrigger(subscription.id)
      .unwrap()
      .then(() => {
        toast.success("Your subscription has been canceled.");
      })
      .catch((error) => {
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "NextPaymentStripe: handleCancel";
          Sentry.captureException(e);
        });
      });
  };

  if (!invoiceUpcoming || subscriptionStripeApi.cancel_at_period_end) {
    return <div>None</div>;
  }
  return (
    <div className="flex flex-wrap gap-x-6 gap-y-2">
      <div className="text-neutral-700">
        <span className="font-bold">
          {getPriceLocalized("USD", invoiceUpcoming.amount_due / 100)}
        </span>{" "}
        on {formatDateOnly(invoiceUpcoming.next_payment_attempt)}
      </div>
      {["trialing", "active"].includes(subscriptionStripeApi.status) ? (
        <Fragment>
          <button
            className="text-blue-700 font-bold text-left"
            onClick={() => setOpen(true)}
          >
            cancel automatic billing
          </button>
          <CancelBilling
            subscription={subscription}
            open={open}
            setOpen={setOpen}
          />
        </Fragment>
      ) : (
        <button
          className="text-blue-700 font-bold text-left"
          onClick={handleCancel}
        >
          cancel subscription
        </button>
      )}
    </div>
  );
};

export default NextPaymentStripe;
