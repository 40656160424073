import React, { useState } from "react";
import { CmSubscriptionPaddle, SubscriptionPaddleApi } from "../../types";
import BadgeStatus from "./BadgeStatus";
import { formatDateOnly } from "../../utils";
import {
  usePaddleApiSubscriptionQuery,
  usePaddleApiSubscriptionResumeBillingMutation,
} from "./subscriptionApiSlice";
import PaymentMethod from "./PaymentMethod";
import { Link, Script } from "gatsby";
import { paddleScriptUrl } from "../../constants";
import toast from "react-hot-toast";
import NextPayment from "./NextPayment";
import ChangeBillingEmail from "./ChangeBillingEmail";
import { handleErrorResponse } from "../../app/apiSlice";
import { SpinnerCircularFixed } from "spinners-react";
import { initializePaddleScript } from "../checkout/checkoutUtils";
import * as Sentry from "@sentry/gatsby";

interface SubscriptionPaddleProps {
  subscription: CmSubscriptionPaddle;
}
const SubscriptionPaddle = ({ subscription }: SubscriptionPaddleProps) => {
  const [changeEmailOpen, setChangeEmailOpen] = useState(false);

  const [resumeBillingTrigger, { isLoading }] =
    usePaddleApiSubscriptionResumeBillingMutation();

  const { data: subscriptionPaddleApiData, refetch } =
    usePaddleApiSubscriptionQuery(subscription.id);
  const [paddleScriptInitialized, setPaddleScriptInitialized] = useState(false);

  const handlePaymentMethodChange = () => {
    window.Paddle.Checkout.open({
      override: subscriptionPaddleApi.update_url,
      successCallback: (data) => {
        toast.success("Payment method updated successfully.");
        refetch();
      },
    });
  };

  const handleResumeBilling = () => {
    resumeBillingTrigger(subscription.id)
      .unwrap()
      .then((success) => {
        toast.success("Billing has been resumed.");
      })
      .catch((error) => {
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "handleResumeBilling";
          Sentry.captureException(e);
        });
      });
  };

  if (!subscriptionPaddleApiData || !subscriptionPaddleApiData.success) {
    return null;
  }
  const subscriptionPaddleApi: SubscriptionPaddleApi =
    subscriptionPaddleApiData.response[0];

  return (
    <div>
      <Script
        src={paddleScriptUrl}
        onLoad={() => {
          initializePaddleScript();
          setPaddleScriptInitialized(true);
        }}
      />
      <div className="grid grid-cols-12 gap-3 items-center">
        <div className="col-span-5 sm:col-span-3 text-neutral-700 self-start">
          Subscription status:
        </div>
        <div
          className="col-span-7 sm:col-span-9"
          data-testid="subscriptionStatus"
        >
          <div className="flex gap-2 flex-wrap">
            <BadgeStatus subscription={subscription} subscriptionPaddleApi={subscriptionPaddleApi} />
            {paddleScriptInitialized &&
              subscriptionPaddleApi.state === "past_due" && (
                <div className="inline-block text-neutral-700">
                  {" "}
                  Billing will be attempted again automatically. Please{" "}
                  <button
                    className="text-blue-700 inline-block font-bold hover:text-blue-600"
                    onClick={handlePaymentMethodChange}
                  >
                    update
                  </button>{" "}
                  your payment method.
                </div>
              )}
            {subscriptionPaddleApi.state === "active" &&
              subscriptionPaddleApi?.paused_from && (
                <div className="text-neutral-700">
                  Subscription ends on{" "}
                  {formatDateOnly(subscriptionPaddleApi.paused_from)}
                </div>
              )}
            {subscription.is_active && subscription?.field_cancel_effective_date && (
              <div className="text-neutral-700">
                Subscription ends on{" "}
                {formatDateOnly(subscription.field_cancel_effective_date, true)}
              </div>
            )}
          </div>
          {subscriptionPaddleApi.state === "active" &&
            subscriptionPaddleApi?.paused_from && (
              <div>
                <button
                  className="text-blue-700 font-bold mt-2 hover:text-blue-600"
                  onClick={handleResumeBilling}
                >
                  resume automatic billing
                </button>
                <SpinnerCircularFixed
                  className="inline-block ml-3"
                  size="15"
                  color="#FFFFFF"
                  secondaryColor="rgb(75, 85, 99)"
                  enabled={isLoading}
                />
              </div>
            )}
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 bg-white rounded-lg py-3 my-6 relative">
        <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700">
          Start date:
        </div>
        <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700">
          {formatDateOnly(subscription.created)}
        </div>

        {subscription?.ended_on && (
          <>
            <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700">
              Ended on:
            </div>
            <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700">
              {formatDateOnly(subscription.ended_on)}
            </div>
          </>
        )}

        <div className="col-span-5 sm:col-span-3 pl-4 text-neutral-700 self-start">
          Plan name:
        </div>
        <div className="col-span-7 sm:col-span-9 pr-4 font-bold text-neutral-700 flex flex-wrap gap-x-6 gap-y-2">
          <div>{subscription.plan_name}</div>
          {subscription.field_billing_type === "month" &&
            !subscriptionPaddleApi?.paused_from &&
            subscriptionPaddleApi.state === "active" && (
              <>
                <Link
                  to={`/subscription/switch-to-annual/${subscription.id}`}
                  className="lg:hidden text-blue-700"
                >
                  switch to annual
                </Link>
                <div className="hidden lg:block absolute right-10 top-1/2 -translate-y-1/2">
                  <Link
                    to={`/subscription/switch-to-annual/${subscription.id}`}
                    className="text-white bg-blue-700 rounded-xl px-6 py-3 hover:bg-blue-600 active:bg-blue-800"
                  >
                    Switch to Annual
                  </Link>
                </div>
              </>
            )}
        </div>
        {subscriptionPaddleApi?.payment_information && (
          <>
            <div className="col-span-5 sm:col-span-3 pl-4 flex items-center text-neutral-700 self-start">
              Payment method:
            </div>
            <div className="col-span-7 sm:col-span-9 pr-4 font-bold flex items-center flex-wrap gap-x-6 gap-y-2">
              <PaymentMethod subscriptionPaddleApi={subscriptionPaddleApi} />
              {paddleScriptInitialized && (
                <div className="">
                  <button
                    className="text-blue-700 inline-block font-bold hover:text-blue-600"
                    onClick={handlePaymentMethodChange}
                  >
                    change
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        <div className="col-span-7 col-start-6 sm:col-span-9 sm:col-start-4">
          <Link
            className="text-blue-700 font-bold hover:text-blue-600"
            to={`/subscription/billing-history/${subscription.id}`}
          >
            view billing history
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-3 items-center">
        <div className="col-span-5 sm:col-span-3 text-neutral-700 self-start">
          Next payment:
        </div>
        <div className="col-span-7 sm:col-span-9">
          <NextPayment
            subscription={subscription}
            subscriptionPaddleApi={subscriptionPaddleApi}
          />
        </div>
        <div className="col-span-5 sm:col-span-3 text-neutral-700 self-start">
          Billing email:
        </div>
        <div className="col-span-7 sm:col-span-9 text-neutral-700 flex flex-wrap gap-x-6 gap-y-2">
          <div className="break-all">{subscriptionPaddleApi.user_email}</div>
          <button
            className="text-blue-700 inline-block font-bold hover:text-blue-600"
            onClick={() => {
              setChangeEmailOpen(true);
            }}
          >
            change
          </button>
          <ChangeBillingEmail
            open={changeEmailOpen}
            setOpen={setChangeEmailOpen}
            email={subscriptionPaddleApi.user_email}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPaddle;
