import React, { useState } from "react";
import toast from "react-hot-toast";
import { handleErrorResponse } from "../../app/apiSlice";
import { CmSubscriptionPaddle, SubscriptionPaddleApi } from "../../types";
import { formatDateOnly, getPriceLocalized } from "../../utils";
import CancelBilling from "./CancelBilling";
import { usePaddleApiSubscriptionCancelMutation } from "./subscriptionApiSlice";
import * as Sentry from "@sentry/gatsby";

interface NextPaymentProps {
  subscription: CmSubscriptionPaddle;
  subscriptionPaddleApi: SubscriptionPaddleApi;
}
const NextPayment = ({
  subscription,
  subscriptionPaddleApi,
}: NextPaymentProps) => {
  const [open, setOpen] = useState(false);
  const [cancelTrigger, { isLoading }] =
    usePaddleApiSubscriptionCancelMutation();

  const handleCancel = () => {
    Sentry.captureMessage('canceling subscription', { contexts: { 'subscription': { subscription } } });
    cancelTrigger(subscription.id)
      .unwrap()
      .then(() => {
        toast.success("Your subscription has been canceled.");
      })
      .catch((error) => {
        console.error('error data', error?.data);
        handleErrorResponse(error).forEach((m) => {
          const e = Error(m);
          e.name = "handleCancel";
          Sentry.captureException(e);
        });
      });
  };

  if (subscriptionPaddleApi.state === "deleted") {
    if (subscription.is_active) {
      return <>None, since billing has been canceled.</>
    }
    return <>None, since this subscription has expired.</>;
  }
  if (subscriptionPaddleApi.state === "paused") {
    return <>None, since this subscription has been paused.</>;
  }
  if (subscriptionPaddleApi?.next_payment) {
    return (
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        <div className="text-neutral-700">
          <span className="font-bold">
            {getPriceLocalized(
              subscriptionPaddleApi.next_payment.currency,
              subscriptionPaddleApi.next_payment.amount
            )}
          </span>{" "}
          including tax on{" "}
          {formatDateOnly(subscriptionPaddleApi.next_payment.date)}
        </div>
        {subscriptionPaddleApi.state === "trialing" && (
          <button
            className="text-blue-700 font-bold text-left"
            onClick={handleCancel}
            disabled={isLoading}
          >
            cancel trial
          </button>
        )}
        {subscriptionPaddleApi.state === "past_due" && (
          <button
            className="text-blue-700 font-bold text-left"
            onClick={handleCancel}
            disabled={isLoading}
          >
            cancel subscription
          </button>
        )}
        {!["trialing", "past_due"].includes(subscriptionPaddleApi.state) && (
          <>
            <button
              className="text-blue-700 font-bold text-left"
              onClick={() => setOpen(true)}
            >
              cancel automatic billing
            </button>
            <CancelBilling
              subscription={subscription}
              open={open}
              setOpen={setOpen}
            />
          </>
        )}
      </div>
    );
  }
  if (subscriptionPaddleApi?.paused_from) {
    return (
      <div className="flex flex-wrap gap-y-1">
        None, since billing has been canceled.
      </div>
    );
  }
  return <>There was an error retrieving the next payment information.</>;
};

export default NextPayment;
