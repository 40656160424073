import React from "react";
import { CmSubscriptionPaddle, SubscriptionPaddleApi } from "../../types";

interface BadgeStatusProps {
  subscriptionPaddleApi: SubscriptionPaddleApi;
  subscription: CmSubscriptionPaddle;
}
const BadgeStatus = ({ subscriptionPaddleApi, subscription }: BadgeStatusProps) => {
  switch (subscriptionPaddleApi.state) {
    case "active":
      if (subscriptionPaddleApi?.paused_from) {
        return (
          <span
            data-cy="subscription-status"
            className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg"
          >
            Active but billing canceled
          </span>
        );
      } else {
        return (
          <span
            data-cy="subscription-status"
            className="inline-block px-2 py-px bg-green-700 text-white rounded-lg"
          >
            Active
          </span>
        );
      }

    case "deleted":
      return (subscription.is_active ? <span
        data-cy="subscription-status"
        className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg"
      >
        Active but billing canceled
      </span> : '') || (
          <span
            data-cy="subscription-status"
            className="inline-block px-2 py-px bg-red-700 text-white rounded-lg"
          >
            Expired
          </span>
        );
    case "past_due":
      return (
        <span
          data-cy="subscription-status"
          className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg"
        >
          Past due
        </span>
      );

    case "trialing":
      return (
        <span
          data-cy="subscription-status"
          className="inline-block px-3 py-px bg-amber-600 text-neutral-800 rounded-lg"
        >
          Trialing
        </span>
      );
  }
  return (
    <span
      data-cy="subscription-status"
      className="inline-block px-2 py-px bg-amber-700 text-neutral-800 rounded-lg"
    >
      {subscriptionPaddleApi.state}
    </span>
  );
};

export default BadgeStatus;
