import React from "react";
import SectionTitle from "../../components/SectionTitle";
import { SpinnerCircularFixed } from "spinners-react";
import Subscriptions from "../../features/subscription/Subscriptions";
import { useRequireAuthentication } from "../../features/user/hooks";
import Navbar from "../../features/navbar/Navbar";
import { HeadProps, Link } from "gatsby";
import { useCurrentUserQuery } from "../../features/user/userApiSlice";
import Footer from "../../features/footer/Footer";
import Seo from "../../features/seo/Seo";

const Settings = () => {
  const { data: currentUser } = useCurrentUserQuery();
  useRequireAuthentication();

  return (
    <>
      <Navbar />
      <div className="px-4 md:px-0 md:w-3/4 lg:w-[960px] mx-auto my-12">
        <div className="mb-4">
          <SectionTitle backgroundStyle="gray" title="Account information" />
        </div>

        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-3 text-neutral-700">Username:</div>
          <div className="col-span-9 font-bold text-neutral-700">
            {currentUser?.is_authenticated ? (
              currentUser.data.name
            ) : (
              <SpinnerCircularFixed
                className="inline-block"
                size="20"
                color="#D3D3D3"
                secondaryColor="rgb(75, 85, 99)"
              />
            )}
            <Link
              className="text-blue-700 inline-block ml-6 font-bold"
              to="/user/change-username"
            >
              change
            </Link>
          </div>
          <div className="col-span-3 text-neutral-700">Email:</div>
          <div className="col-span-9">
            {currentUser?.is_authenticated ? (
              <div>
                <div className="font-bold text-neutral-700">
                  {currentUser.data.mail}{" "}
                  <Link
                    className="text-blue-700 font-bold inline-block ml-6"
                    to="/user/change-email"
                  >
                    change
                  </Link>
                </div>
                <div className="text-neutral-500">
                  This email is for password resets, and other emails sent
                  directly by Giancoli Answers. Billing emails, such as
                  receipts, are sent by our billing partner Paddle to the email
                  address shown below in the Subscription details.
                </div>
              </div>
            ) : (
              <SpinnerCircularFixed
                className="inline-block"
                size="20"
                color="#D3D3D3"
                secondaryColor="rgb(75, 85, 99)"
              />
            )}
          </div>
          <div className="col-span-3 text-neutral-700">Password:</div>
          <div className="col-span-9 text-neutral-700">
            ********
            <Link
              className="text-blue-700 inline-block ml-6 font-bold"
              to="/user/change-password"
            >
              change
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-neutral-100 min-h-screen">
        <div className="px-4 md:px-0 md:w-3/4 lg:w-[960px] mx-auto py-4">
          <div className="my-4">
            <Subscriptions />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Settings;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Settings | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
