import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { SubscriptionPaddleApi } from "../../types";
import {
  isPaymentMethodCard,
  isPaymentMethodCardGeneric,
} from "./subscriptionApiSlice";

interface PaymentIconsQueryData {
  allFile: {
    nodes: {
      extension: string;
      name: string;
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    }[];
  };
}

interface PaymentMethodProps {
  subscriptionPaddleApi: SubscriptionPaddleApi;
}

const PaymentMethod = ({ subscriptionPaddleApi }: PaymentMethodProps) => {
  const data: PaymentIconsQueryData = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "paymentIcons" } }) {
        nodes {
          extension
          childImageSharp {
            gatsbyImageData(height: 25, layout: CONSTRAINED)
          }
          name
        }
      }
    }
  `);
  const paypalImage = getImage(
    data?.allFile.nodes.find((item) => item.name === "paypal")?.childImageSharp
      .gatsbyImageData
  );
  const genericImage = getImage(
    data?.allFile.nodes.find((item) => item.name === "generic")?.childImageSharp
      .gatsbyImageData
  );
  if (isPaymentMethodCard(subscriptionPaddleApi.payment_information)) {
    const cardBrand = subscriptionPaddleApi.payment_information.card_type;
    const cardImage =
      getImage(
        data?.allFile.nodes.find((item) => item.name === cardBrand)
          ?.childImageSharp.gatsbyImageData
      ) ?? genericImage;
    return (
      <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
        <div className="w-12 flex items-center justify-center">
          <GatsbyImage image={cardImage} alt="card icon" />
        </div>
        <div className="text-neutral-600 font-normal">
          ******{subscriptionPaddleApi.payment_information.last_four_digits}
        </div>
        <div className="text-neutral-600 font-normal">
          Exp: {subscriptionPaddleApi.payment_information.expiry_date}
        </div>
      </div>
    );
  }

  if (isPaymentMethodCardGeneric(subscriptionPaddleApi.payment_information)) {
    return (
      <div className="w-12 flex items-center justify-center">
        <GatsbyImage image={genericImage} alt="card icon" />
      </div>
    );
  }

  return <GatsbyImage image={paypalImage} alt="PayPal icon" />;
};

export default PaymentMethod;
